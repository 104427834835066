import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf'; // Importamos jsPDF
import 'jspdf-autotable'; // Importamos la extensión autoTable
import Swal from 'sweetalert2'; // Importamos SweetAlert

const TablaMovimientos = () => {
  const [clientes, setClientes] = useState([]);
  const [cliente, setCliente] = useState('');
  const [razon, setRazon] = useState('');
  const [monto, setMonto] = useState('');
  const [pago, setPago] = useState('');
  const [tipoTarjeta, setTipoTarjeta] = useState('');
  const [valorTarjeta, setValorTarjeta] = useState('');
  const [parcialEfectivo, setParcialEfectivo] = useState('');
  const [parcialCheque, setParcialCheque] = useState('');
  const [editIndex, setEditIndex] = useState(null);

  // Función para verificar si los datos han caducado
  const checkDataExpiration = () => {
    const timestamp = localStorage.getItem('timestamp');
    if (timestamp) {
      const currentTime = new Date().getTime();
      const elapsedTime = currentTime - timestamp;
      const twentyFourHours = 24 * 60 * 60 * 1000; // 24 horas en milisegundos

      if (elapsedTime > twentyFourHours) {
        // Si han pasado más de 24 horas, eliminamos los datos
        localStorage.removeItem('clientes');
        localStorage.removeItem('timestamp');
      }
    }
  };

  // Función para cargar los datos desde localStorage
  const loadDataFromLocalStorage = () => {
    const savedClientes = localStorage.getItem('clientes');
    if (savedClientes) {
      setClientes(JSON.parse(savedClientes));
    }
  };

  // Guardar datos en localStorage
  const saveDataToLocalStorage = () => {
    localStorage.setItem('clientes', JSON.stringify(clientes));
    localStorage.setItem('timestamp', new Date().getTime().toString());
  };

  // Al montar el componente, verificamos la expiración y cargamos los datos
  useEffect(() => {
    checkDataExpiration();
    loadDataFromLocalStorage();
  }, []);

  useEffect(() => {
    // Guardamos los datos cada vez que se actualiza la lista de clientes
    saveDataToLocalStorage();
  }, [clientes]);

  const handleAddOrUpdateRow = () => {
    if (!cliente || !razon || !monto || !pago) {
      alert('Por favor complete todos los campos');
      return;
    }

    const newRow = {
      cliente,
      razon,
      monto,
      pago,
      tipoTarjeta,
      valorTarjeta,
      parcialEfectivo,
      parcialCheque,
    };

    if (editIndex !== null) {
      const updatedClientes = [...clientes];
      updatedClientes[editIndex] = newRow;
      setClientes(updatedClientes);
      setEditIndex(null);
    } else {
      setClientes([...clientes, newRow]);
    }

    // Limpiar los inputs
    setCliente('');
    setRazon('');
    setMonto('');
    setPago('');
    setTipoTarjeta('');
    setValorTarjeta('');
    setParcialEfectivo('');
    setParcialCheque('');
  };

  const handleEditRow = (index) => {
    const row = clientes[index];
    setCliente(row.cliente);
    setRazon(row.razon);
    setMonto(row.monto);
    setPago(row.pago);
    setTipoTarjeta(row.tipoTarjeta);
    setValorTarjeta(row.valorTarjeta);
    setParcialEfectivo(row.parcialEfectivo);
    setParcialCheque(row.parcialCheque);
    setEditIndex(index);
  };

  const handleDeleteRow = (index) => {
    const updatedClientes = clientes.filter((_, i) => i !== index);
    setClientes(updatedClientes);
  };

  // Calcular los totales por tipo de pago
  const calculateTotals = () => {
    let efectivoTotal = 0;
    let transfTotal = 0;
    let tarjetaCreditoTotal = 0;
    let tarjetaDebitoTotal = 0;
    let chequeTotal = 0;
    let ntcTotal = 0;

    clientes.forEach((row) => {
      const monto = parseFloat(row.monto);
      if (row.pago === "EFECTIVO") {
        efectivoTotal += monto;
      } else if (row.pago === "TRANSFERENCIA") {
        transfTotal += monto;
      } else if (row.pago === "TARJETA") {
        if (row.tipoTarjeta === "CREDITO") {
          tarjetaCreditoTotal += monto;
        } else if (row.tipoTarjeta === "DEBITO") {
          tarjetaDebitoTotal += monto;
        }
      } else if (row.pago === "CHEQUE") {
        chequeTotal += monto;
      } else if (row.pago === "PARCIAL CH/FT") {
        // Para PARCIAL CH/FT, sumamos tanto en efectivo como en cheque
        efectivoTotal += parseFloat(row.parcialEfectivo || 0);
        chequeTotal += parseFloat(row.parcialCheque || 0);
      } else if (row.pago === "PARCIAL FT/TARJETA") {
        // Si el pago es "PARCIAL FT/TARJETA", sumamos el efectivo y discriminamos la tarjeta
        efectivoTotal += parseFloat(row.parcialEfectivo || 0);
        if (row.tipoTarjeta === "CREDITO") {
          tarjetaCreditoTotal += parseFloat(row.valorTarjeta || 0);
        } else if (row.tipoTarjeta === "DEBITO") {
          tarjetaDebitoTotal += parseFloat(row.valorTarjeta || 0);
        }
      } else if (row.pago === "NTC") {
        ntcTotal += monto;
      } else if (row.pago === "PARCIAL FT/TRANSFERENCIA") {
        // Si el pago es "PARCIAL FT/TRANSFERENCIA", sumamos tanto en efectivo como en transferencia
        efectivoTotal += parseFloat(row.parcialEfectivo || 0);
        transfTotal += parseFloat(row.parcialCheque || 0);
      }
    });

    return { efectivoTotal, transfTotal, tarjetaCreditoTotal, tarjetaDebitoTotal, chequeTotal, ntcTotal };
  };

  const { efectivoTotal, transfTotal, tarjetaCreditoTotal, tarjetaDebitoTotal, chequeTotal, ntcTotal } = calculateTotals();

  // Función para cerrar el día
  const handleCerrarDia = () => {
    const doc = new jsPDF();

    // Obtener la fecha actual en formato dd/mm/yyyy
    const fecha = new Date().toLocaleDateString('es-ES'); // Esto devuelve la fecha en formato dd/mm/yyyy

    // Agregar el título con la fecha
    doc.text(`Movimientos del ${fecha}`, 14, 20);

    // Seleccionar la tabla HTML y pasarla a PDF usando autoTable
    const tabla = document.querySelector('table');
    doc.autoTable({ html: tabla, startY: 30 }); // El "startY" define dónde empieza la tabla en el PDF

    // Agregar los totales al PDF
    const { efectivoTotal, transfTotal, tarjetaCreditoTotal, tarjetaDebitoTotal, chequeTotal, ntcTotal } = calculateTotals();

    // Agregar resumen de totales
    doc.setFontSize(12);
    doc.text(`Resumen de Totales`, 14, doc.autoTable.previous.finalY + 10);
    doc.text(`Efectivo: ${efectivoTotal.toFixed(2)}`, 14, doc.autoTable.previous.finalY + 15);
    doc.text(`Transferencia: ${transfTotal.toFixed(2)}`, 14, doc.autoTable.previous.finalY + 20);
    doc.text(`Credito Tarjeta: ${tarjetaCreditoTotal.toFixed(2)}`, 14, doc.autoTable.previous.finalY + 25);
    doc.text(`Debito Tarjeta: ${tarjetaDebitoTotal.toFixed(2)}`, 14, doc.autoTable.previous.finalY + 30);
    doc.text(`Cheque: ${chequeTotal.toFixed(2)}`, 14, doc.autoTable.previous.finalY + 35);
    doc.text(`NTC: ${ntcTotal.toFixed(2)}`, 14, doc.autoTable.previous.finalY + 40);

    // Guardar el PDF
    doc.save('movimientos_diario.pdf');

    // Mostrar el SweetAlert
    Swal.fire({
      title: '¡Éxito!',
      text: 'Se cerró el día y los datos fueron eliminados.',
      icon: 'success',
      confirmButtonText: 'Cerrar'
    });

    // Limpiar los datos después de un retraso
    setTimeout(() => {
      localStorage.clear(); // Limpiar el localStorage
      setClientes([]); // Limpiar los datos de la tabla
    }, 2000); // Retraso para permitir que el usuario vea el PDF
};


  return (
    <div className="container mt-5">
      <h2>Modulo Ingresos</h2>

      {/* Formulario para agregar o editar un movimiento */}
      <div className="mb-3 row">
        <div className="col-3">
          <input
            type="text"
            className="form-control"
            placeholder="Cliente"
            value={cliente}
            onChange={(e) => setCliente(e.target.value)}
          />
        </div>
        <div className="col-3">
          <input
            type="text"
            className="form-control"
            placeholder="Razón"
            value={razon}
            onChange={(e) => setRazon(e.target.value)}
          />
        </div>
        <div className="col-2">
          <input
            type="number"
            className="form-control"
            placeholder="Monto"
            value={monto}
            onChange={(e) => setMonto(e.target.value)}
          />
        </div>
        <div className="col-2">
          <select
            className="form-control"
            value={pago}
            onChange={(e) => setPago(e.target.value)}
          >
            <option value="">Seleccionar Pago</option>
            <option value="EFECTIVO">EFECTIVO</option>
            <option value="TRANSFERENCIA">TRANSFERENCIA</option>
            <option value="TARJETA">TARJETA</option>
            <option value="CHEQUE">CHEQUE</option>
            <option value="PARCIAL CH/FT">PARCIAL CH/FT</option>
            <option value="PARCIAL FT/TARJETA">PARCIAL FT/TARJETA</option>
            <option value="NTC">NTC</option>
            <option value="PARCIAL FT/TRANSFERENCIA">PARCIAL FT/TRANSFERENCIA</option>
          </select>
        </div>

        {pago === "TARJETA" && (
          <div className="col-2">
            <select
              className="form-control"
              value={tipoTarjeta}
              onChange={(e) => setTipoTarjeta(e.target.value)}
            >
              <option value="">Seleccionar Tipo de Tarjeta</option>
              <option value="CREDITO">CREDITO</option>
              <option value="DEBITO">DEBITO</option>
            </select>
          </div>
        )}

        <div className="col-2">
          <button className="btn btn-primary w-100" onClick={handleAddOrUpdateRow}>
            {editIndex !== null ? 'Actualizar Movimiento' : 'Agregar Movimiento'}
          </button>
        </div>
      </div>

      {/* Mostrar inputs adicionales si se selecciona "PARCIAL FT/CH", "PARCIAL FT/TARJETA" o "PARCIAL FT/TRANSFERENCIA" */}
      {(pago === "PARCIAL CH/FT" || pago === "PARCIAL FT/TARJETA" || pago === "PARCIAL FT/TRANSFERENCIA") && (
        <div className="mb-3 row">
          {(pago === "PARCIAL CH/FT") && (
            <div className="col-3">
              <input
                type="number"
                className="form-control"
                placeholder="PARCIAL EFECTIVO"
                value={parcialEfectivo}
                onChange={(e) => setParcialEfectivo(e.target.value)}
              />
            </div>
          )}
          {(pago === "PARCIAL CH/FT") && (
            <div className="col-3">
              <input
                type="number"
                className="form-control"
                placeholder="PARCIAL CHEQUE"
                value={parcialCheque}
                onChange={(e) => setParcialCheque(e.target.value)}
              />
            </div>
          )}
          {(pago === "PARCIAL FT/TARJETA") && (
            <>
              <div className="col-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="PARCIAL EFECTIVO"
                  value={parcialEfectivo}
                  onChange={(e) => setParcialEfectivo(e.target.value)}
                />
              </div>
              <div className="col-3">
                <select
                  className="form-control"
                  value={tipoTarjeta}
                  onChange={(e) => setTipoTarjeta(e.target.value)}
                >
                  <option value="">Seleccionar Tipo de Tarjeta</option>
                  <option value="CREDITO">CREDITO</option>
                  <option value="DEBITO">DEBITO</option>
                </select>
              </div>
              {tipoTarjeta && (
                <div className="col-3">
                  <input
                    type="number"
                    className="form-control"
                    placeholder={`Monto ${tipoTarjeta}`}
                    value={valorTarjeta}
                    onChange={(e) => setValorTarjeta(e.target.value)}
                  />
                </div>
              )}
            </>
          )}
          {(pago === "PARCIAL FT/TRANSFERENCIA") && (
            <>
              <div className="col-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="PARCIAL EFECTIVO"
                  value={parcialEfectivo}
                  onChange={(e) => setParcialEfectivo(e.target.value)}
                />
              </div>
              <div className="col-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="PARCIAL TRANSFERENCIA"
                  value={parcialCheque}
                  onChange={(e) => setParcialCheque(e.target.value)}
                />
              </div>
            </>
          )}
        </div>
      )}

      {/* Tabla de movimientos */}
      <table className="table table-striped">
        <thead>
          <tr>
            <th>Cliente</th>
            <th>Razón</th>
            <th>Monto</th>
            <th>Pago</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {clientes.map((row, index) => (
            <tr key={index}>
              <td>{row.cliente}</td>
              <td>{row.razon}</td>
              <td>{row.monto}</td>
              <td>
                {row.pago}
                {row.pago === "TARJETA" && (
                  <p>{row.tipoTarjeta}</p>
                )}
                {(row.pago === "PARCIAL FT/TARJETA") && (
                  <div>
                    <p>Efectivo: {row.parcialEfectivo}</p>
                    <p>Tarjeta ({row.tipoTarjeta}): {row.valorTarjeta}</p>
                  </div>
                )}
                {(row.pago === "PARCIAL CH/FT") && (
                  <div>
                    <p>Efectivo: {row.parcialEfectivo}</p>
                    <p>Cheque: {row.parcialCheque}</p>
                  </div>
                )}
                {(row.pago === "PARCIAL FT/TRANSFERENCIA") && (
                  <div>
                    <p>Efectivo: {row.parcialEfectivo}</p>
                    <p>Transferencia: {row.parcialCheque}</p>
                  </div>
                )}
              </td>
              <td>
                <button className="btn btn-warning btn-sm me-2" onClick={() => handleEditRow(index)}>
                  Editar
                </button>
                <button className="btn btn-danger btn-sm" onClick={() => handleDeleteRow(index)}>
                  Eliminar
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Resumen de totales */}
      <div className="mb-4 row">
        <h4>Resumen de Totales</h4>
        <div className="col-4">
          <p><strong>Efectivo: </strong>{efectivoTotal.toFixed(2)}</p>
          <p><strong>Transferencia: </strong>{transfTotal.toFixed(2)}</p>
        </div>
        <div className="col-4">
          <p><strong>Credito Tarjeta: </strong>{tarjetaCreditoTotal.toFixed(2)}</p>
          <p><strong>Debito Tarjeta: </strong>{tarjetaDebitoTotal.toFixed(2)}</p>
        </div>
        <div className="col-4">
          <p><strong>Cheque: </strong>{chequeTotal.toFixed(2)}</p>
          <p><strong>NTC: </strong>{ntcTotal.toFixed(2)}</p>
        </div>
      </div>

      {/* Botón para cerrar el día y generar el PDF */}
      <button className="btn btn-danger" onClick={handleCerrarDia}>Cerrar Día</button>
    </div>
  );
};

export default TablaMovimientos;
